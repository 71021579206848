import React, { useCallback, useEffect, useRef, useState } from "react";
import { createContainer } from "unstated-next";

export interface Example {
  user: string;
  assistant?: string;
  newUser?: boolean;
  abbreviatedUser?: string;
}

export interface ExampleSection {
  prompts: { user: string }[];
  section: string;
}

export type ExamplesLayout = "grid" | "columns";
export type LayoutVariant =
  | "center"
  | "bottomRight"
  | "bottomLeft"
  | null
  | undefined;

type onExampleClickFunction = (example: Example) => Promise<any>;

export interface ChatBotUIProviderConfigProps {
  initialValue?: string;
  initialPlaceholder?: string;
  examples?: Example[];
  exampleSections?: ExampleSection[];
  onExampleClicked?: onExampleClickFunction;
  onSubmit?: (value: string, eventProps?: any) => void;
  onAction?: (formData: FormData) => void;
  variant: LayoutVariant;
  overlay?: boolean; // Be more specific if possible
  alwaysShowExamples?: boolean;
  autoFocus?: boolean;
  examplesLabel?: string | React.ReactNode;
  showSourceDocs?: boolean;
  forcedDisabled?: boolean;
  showAssistantAvatar?: boolean;
  toolInUse?: string;
  examplesLayout?: ExamplesLayout;
  scrollElement?: string;
}

function useContainer(initialState?: ChatBotUIProviderConfigProps) {
  const {
    scrollElement,
    initialPlaceholder = "Type a message...",
    examples: initialExamples = [],
    exampleSections,
    alwaysShowExamples = true,
    autoFocus,
    examplesLabel: initalExamplesLabel,
  } = initialState || {};

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const randomIndex = Math.floor(
    Math.random() * (exampleSections?.length || 0)
  );
  const [examples, setExamples] = useState<Example[]>(
    exampleSections?.[randomIndex]?.prompts || initialExamples
  );
  const [examplesLabel, setExamplesLabel] = useState(
    exampleSections?.[randomIndex]?.section || initalExamplesLabel
  );
  const [placeholder, setPlaceholder] = useState(initialPlaceholder);

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(1);
  const [examplesRefreshing, setExamplesRefreshing] = useState(false);

  const handleNewNotification = useCallback((currentlyOpen) => {
    if (currentlyOpen) {
      setNotifications(0);
      return currentlyOpen;
    }
    setNotifications((notifications = 0) => {
      return notifications + 1;
    });
    return currentlyOpen;
  }, []);

  const scrollToBottom = useCallback((behavior?: any) => {
    setTimeout(() => {
      if (scrollElement === "body") {
        window.scrollTo(0, document.body.scrollHeight);
        return;
      }

      if (typeof scrollAreaRef.current?.scroll === "function") {
        scrollAreaRef.current.scroll({
          top: scrollAreaRef.current.scrollHeight,
          behavior,
        });
      }
    }, 250);
  }, []);

  useEffect(() => {
    if (!initialExamples?.length) return;
    setExamples(initialExamples);
  }, [initialExamples]);

  useEffect(() => {
    if (open) {
      setNotifications(0);
      scrollToBottom();
    }
  }, [open, scrollToBottom]);

  useEffect(() => {
    scrollToBottom("instant");
  }, []);

  return {
    ...(initialState as ChatBotUIProviderConfigProps),
    open,
    examples,
    placeholder,
    notifications,
    inputRef,
    alwaysShowExamples,
    autoFocus,
    examplesRefreshing,
    examplesLabel,
    scrollAreaRef,
    setOpen,
    setExamples,
    setExamplesLabel,
    scrollToBottom,
    handleNewNotification,
  };
}

export const {
  Provider: ChatBotUIConfigProvider,
  useContainer: useChatBotUIConfig,
} = createContainer(useContainer);
