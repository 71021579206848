import { ArrowUpIcon } from "@radix-ui/react-icons";
import { motion } from "framer-motion";
import { cn } from "ui/lib/utils";

import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useChatBotAIProps } from "./ChatBotAIPropsProvider";
import { useChatBotActions } from "./ChatBotActionsProvider";
import { useChatBotUIConfig } from "./ChatBotUIProvider";

export const ChatBotInput = ({ maxRows = 4, className = "" }) => {
  const [rowHeight, setRowHeight] = useState(0);
  const { placeholder, inputRef, autoFocus, forcedDisabled } =
    useChatBotUIConfig();

  const { input, setInput, isLoading } = useChatBotAIProps();
  const { handleSubmit, onAction, handleOnKeyDown } = useChatBotActions();

  return (
    <form
      className="relative w-full flex"
      action={onAction}
      onSubmit={handleSubmit}
    >
      <TextareaAutosize
        ref={inputRef}
        value={input}
        name="chat-input"
        onHeightChange={setRowHeight}
        onChange={(e) => setInput(e.target.value)}
        className={cn(
          "pr-10 pl-4 py-2 w-full bg-secondary border resize-none",
          {
            "rounded-full": rowHeight <= 50,
            "rounded-xl": rowHeight > 50,
          },
          className
        )}
        onKeyDown={handleOnKeyDown}
        placeholder={placeholder}
        maxRows={maxRows}
        autoFocus={autoFocus}
        disabled={forcedDisabled}
      />
      <motion.button
        type="submit"
        disabled={!input || isLoading || forcedDisabled}
        className={cn(
          "absolute right-1 bottom-[0.3rem] rounded-full w-8 h-8 flex-center block lg:shadow-button",
          {
            "bg-neutral-500": !input,
            "bg-primary shadow-button": !!input,
          }
        )}
      >
        <ArrowUpIcon className={"text-black w-4 h-4 lg:w-5 lg:h-5 mx-auto"} />
      </motion.button>
    </form>
  );
};
