import { Message } from "ai/react";
import { motion } from "framer-motion";
import { useAtomValue } from "jotai";
import { Pencil } from "lucide-react";
import Link from "next/link";
import { useTeams } from "queries/teams";
import { useWorkoutByID } from "queries/workout";
import { Workout } from "queries/workout/types";
import { memo, useMemo } from "react";
import { Linkify } from "ui/components/linkify";
import { useIsMobile } from "ui/hooks/use-media-query";
import { cn } from "ui/lib/utils";
import { TeamsComponent } from "web/components/arnie/team-card";
import { getCurrentDayOfWeekIndex } from "web/helpers/misc";
import { Skeleton } from "../primitives/skeleton";
import { AssistantMessageBubble } from "./AssistantMessageBubble";
import { streamingTextAtom } from "./ChatBotAIPropsProvider";

const StreamingTextBubble = memo(
  ({
    messageLength,
    showAssistantAvatar,
  }: {
    showAssistantAvatar?: boolean;
    messageLength: number;
  }) => {
    const streamingText = useAtomValue(streamingTextAtom);

    if (!streamingText) return null;

    return (
      <AssistantMessageBubble
        index={messageLength + 1}
        messagesLength={messageLength}
        loading={true}
        isLastMessage={true}
        showAssistantAvatar={showAssistantAvatar}
        message={{ role: "system", content: streamingText, id: "temp" }}
      />
    );
  }
);

const WorkoutOverlayComponent = ({ session }) => {
  if (!session) return null;
  return (
    <div className="absolute inset-0 flex center p-12">
      <img
        alt={`ladder workout completion badge ${session.completionNumber}`}
        src={`https://ladderteams.imgix.net/badges/badge-${session.completionNumber}.png?w=160`}
      />
    </div>
  );
};

const MotionLink = motion(Link);

const WorkoutCard = ({
  workout,
  index,
}: {
  workout: Workout;
  index: number;
}) => {
  const {
    name,
    posterLandscapeImgixURL,
    posterPortraitImgixURL,
    bootcampId,
    date,
    duration,
    type,
  } = workout;

  const { getTeamById } = useTeams();
  const team = getTeamById(bootcampId);

  const isMobile = useIsMobile();

  const handleClick = async () => {};

  const currentDayIndex = getCurrentDayOfWeekIndex();
  const isToday = useMemo(
    () => index === currentDayIndex,
    [index, currentDayIndex]
  );

  return (
    <MotionLink
      whileHover={{ scale: 1.02 }}
      onClick={handleClick}
      href={`/app/workout/${workout.id}`}
      id={isToday ? "today-workout-item" : `workout-item-${index}`}
      className={cn(
        "w-[calc(100vw-32px)] max-w-lg h-[60vh] lg:w-[12rem] lg:h-[14rem] 2xl:w-[16rem] 2xl:h-[18rem] text-left lg:hover:scale-[1.02] block rounded border-[3px] hover:border-primary overflow-hidden cursor-pointer group relative transition-all",
        {
          ...(isMobile && {
            // "border-white/50": !isToday && isActive,
            "border-primary": isToday,
            border: !isToday,
          }),
        }
      )}
    >
      <div className="absolute inset-0 z-10 flex flex-col justify-between p-3 align-start">
        <div />
        <div>
          <h2 className="text-3xl lg:text-base font-bold">{name}</h2>
          <div className="text-base lg:text-xs space-x-1 opacity-80 text-foreground">
            <span>{Math.round(duration / 60)} min</span>
            <span>•</span>
            <span>{type}</span>
          </div>
          {/* <div className="lg:hidden mt-4">
            <WorkoutActions workoutID={workout?.id} />
          </div> */}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full h-20 pointer-events-none bg-gradient-to-t from-background to-transparent" />
      {/* <AspectRatioPrimitive.Root
      ratio={16 / 9}
      className="w-full h-full"
    > */}
      <img
        alt={name}
        src={
          (posterLandscapeImgixURL ||
            posterPortraitImgixURL ||
            team?.posterImgixURL) +
          "?fp-x=0.75&fp-y=0.56&fp-z=1&fp-debug=false&w=800&ar=0.85:1&fit=crop"
        }
        className="object-cover w-full h-full"
      />
      {/* </AspectRatioPrimitive.Root> */}
    </MotionLink>
  );
};
const WorkoutCardWrapper = ({ id, index }) => {
  const { data: workout, isLoading, isError } = useWorkoutByID(id);

  if (isLoading)
    return (
      <Skeleton className="w-[calc(100vw-32px)] max-w-lg h-[60vh] lg:w-[12rem] lg:h-[14rem] 2xl:w-[16rem] 2xl:h-[18rem] block rounded overflow-hidden relative" />
    );
  if (isError) return <div>Error fetching workout</div>;
  if (!workout) return <div>Error fetching workout</div>;

  return <WorkoutCard workout={workout} index={index} />;
};

const WorkoutsComponents = ({ workoutIDs }) => {
  return (
    <motion.div
      id={"workout-scroll-container"}
      className="relative hide-scrollbar flex pt-2 pb-4 pr-6 space-x-2 overflow-auto snap-x snap-mandatory overscroll-x-contain"
    >
      {workoutIDs.map((id, index) => (
        <div className="snap-center snap-always" key={index}>
          <WorkoutCardWrapper id={id} index={index} />
        </div>
      ))}
    </motion.div>
  );
};

const RenderTool = ({ toolName, result }) => {
  if (toolName.startsWith("render") && result?.length) {
    switch (toolName) {
      case "renderTeamCards": {
        return <TeamsComponent teams={result} />;
      }
      case "renderWorkoutCards": {
        return <WorkoutsComponents workoutIDs={result} />;
      }
    }
  }
  return null;
};

export const MessageList = memo(
  ({
    messages,
    inputRef,
    loading,
    setInput,
    showAssistantAvatar,
  }: {
    messages: Message[];
    loading?: boolean;
    inputRef: any;
    setInput: any;
    showAssistantAvatar?: boolean;
  }) => {
    return (
      <>
        {messages.map((message, index) => {
          if (message.toolInvocations?.length) {
            // @ts-ignore
            return message.toolInvocations.map(({ result, toolName }, key) => {
              return (
                <RenderTool key={index} toolName={toolName} result={result} />
              );
            });
          }
          if (message.tool_call_id && message.content) {
            // const data = JSON.parse(message.content);
            // return <RenderTool key={index} {...data} />;
            return null;
          }
          return message.role === "assistant" ? (
            <AssistantMessageBubble
              key={index}
              index={index}
              message={message}
              isLastMessage={!loading && messages.length - 1 === index}
              showAssistantAvatar={showAssistantAvatar}
              messagesLength={messages.length}
            />
          ) : (
            <motion.div
              key={index}
              data-type={`user-message-${index}`}
              className="flex space-x-2 group items-center justify-end"
            >
              {index === messages.length - 2 ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    inputRef.current?.focus();
                    setInput(message.content);
                  }}
                  className="opacity-0 group-hover:opacity-100 p-2 rounded-full bg-secondary shadow-button"
                >
                  <Pencil className="w-3 h-3 text-foreground" />
                </button>
              ) : (
                <div className="min-w-[20px]" />
              )}
              <motion.div className="py-2 px-4 w-auto ml-auto bg-secondary text-foreground rounded-2xl rounded-br-none">
                <Linkify>{message.content}</Linkify>
              </motion.div>
            </motion.div>
          );
        })}
        <StreamingTextBubble
          showAssistantAvatar={showAssistantAvatar}
          messageLength={messages.length}
        />
      </>
    );
  }
);
