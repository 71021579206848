import { memo, useMemo } from "react";
import { cn } from "ui/lib/utils";
import AnimatedShinyText from "../shiny-text";
import { useChatBotAIProps } from "./ChatBotAIPropsProvider";
import { useChatBotUIConfig } from "./ChatBotUIProvider";
import { ExampleList } from "./ExampleList";
import { MessageList } from "./MessageList";

export const ChatBotBody = memo(
  ({ className = "" }: { className?: string }) => {
    const {
      toolInUse,
      examples,
      setExamples,
      alwaysShowExamples,
      scrollAreaRef,
      scrollToBottom,
      inputRef,
      examplesLabel,
      setExamplesLabel,
      exampleSections,
      examplesLayout = "columns",
      showAssistantAvatar,
    } = useChatBotUIConfig();

    const { messages, setInput, isLoading } = useChatBotAIProps();

    const messagesLength = useMemo(() => messages.length, [messages]);
    return (
      <div
        ref={scrollAreaRef}
        className={cn(
          "flex-1 flex relative bg-no-repeat bg-bottom bg-contain bg-origin-padding overflow-auto overflow-x-hidden h-auto p-4 bg-background",
          className
        )}
      >
        <div className="flex flex-1 flex-col space-y-2 max-w-full">
          <MessageList
            messages={messages}
            inputRef={inputRef}
            setInput={setInput}
            loading={isLoading}
            showAssistantAvatar={showAssistantAvatar}
          />
          {toolInUse ? (
            <div className="my-4">
              <AnimatedShinyText shimmerWidth={200}>
                {toolInUse}
              </AnimatedShinyText>
            </div>
          ) : null}
          <ExampleList
            examples={examples}
            examplesLabel={examplesLabel}
            exampleSections={exampleSections}
            examplesLayout={examplesLayout}
            setExamples={setExamples}
            setExamplesLabel={setExamplesLabel}
            scrollToBottom={scrollToBottom}
            messagesLength={messagesLength}
            alwaysShowExamples={alwaysShowExamples}
          />
        </div>
      </div>
    );
  }
);
