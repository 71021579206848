import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTimeout } from "ui/hooks/useTimeout";
import { cn } from "ui/lib/utils";

export const ContentDots = ({ length, handleScrollTo, activeIndex }) => {
  const [visible, setVisible] = useState(false);
  useTimeout(() => setVisible(true), 1000);

  const dots = useMemo(() => Array.from({ length }), [length]);

  const getDotSize = (index) => {
    const diff = Math.abs(activeIndex - index);
    if (diff >= 5) return 0;
    if (diff >= 4) return 3;
    if (diff >= 3) return 5;
    return index === activeIndex ? 8 : 7;
  };

  return (
    <div className="flex justify-center">
      <div className="flex items-center space-x-2 px-2 py-2 rounded-[500px] bg-white/10">
        {dots.map((_, index) => {
          const size = getDotSize(index);
          const hidden = Math.abs(activeIndex - index) >= 5;

          return (
            <div key={index} className={hidden ? "absolute" : "relative"}>
              <motion.div
                onClick={() => handleScrollTo(index)}
                initial={false}
                animate={{
                  width: size,
                  height: size,
                  opacity: hidden ? 0.5 : 1,
                }}
                className={cn(
                  "cursor-pointer text-center list-none rounded-full",
                  {
                    "bg-white": index === activeIndex && visible,
                    "bg-[#545454]": !(index === activeIndex && visible),
                  }
                )}
                aria-label={`Go to slide ${index + 1}`}
              />
              {/* {index === activeIndex && visible && (
                <motion.div
                  initial={false}
                  layoutId="active-dot"
                  layout="position"
                  className="absolute inset-0 rounded-full bg-white shadow-lg z-10 max-w-[10px] max-h-[10px]"
                />
              )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ContentDots.propTypes = {
  length: PropTypes.number.isRequired,
  handleScrollTo: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired,
};
