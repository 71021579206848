import { useChat } from "ai/react";
import { Provider as StreamingTextProvider, atom, useSetAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createContainer } from "unstated-next";

export type ChatBotAIProviderConfigProps = Pick<
  ReturnType<typeof useChat>,
  | "isLoading"
  | "input"
  | "setInput"
  | "handleSubmit"
  | "append"
  | "messages"
  | "setMessages"
>;

export const streamingTextAtom = atom<string | null>(null);

const useMessageList = (messages, isLoading) => {
  const [updatedMessages, setUpdatedMessages] = useState(messages);
  const hasSetMessagesRef = useRef(false);
  // @ts-ignore
  const setStreamingText = useSetAtom(streamingTextAtom);

  useEffect(() => {
    if (isLoading && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.role === "assistant" && !lastMessage.toolInvocations) {
        if (!hasSetMessagesRef.current) {
          hasSetMessagesRef.current = true;
          setUpdatedMessages(messages.slice(0, -1));
        }
        setStreamingText(lastMessage.content);
        return;
      }
    }
    hasSetMessagesRef.current = false;
    setStreamingText(null);
    setUpdatedMessages(messages);
  }, [isLoading, messages, setStreamingText]);

  return { messages: updatedMessages };
};

function useContainer(initialState?: {
  chatProps: ChatBotAIProviderConfigProps;
}) {
  const { chatProps } = initialState as {
    chatProps: ChatBotAIProviderConfigProps;
  };
  const { messages, ...rest } = chatProps;

  const filteredMessages = useMemo(
    () => messages.filter(Boolean),
    // .filter((message) => !message.toolInvocations?.length)
    // .filter((message) => !message.tool_call_id),
    // .filter((message) => !!message.content || !!message.content),
    [messages]
  );

  const { messages: updatedMessages } = useMessageList(
    filteredMessages,
    rest.isLoading
  );

  const memoizedChatProps = useMemo(
    () => ({
      ...rest,
      messages: updatedMessages,
    }),
    [rest, updatedMessages]
  );

  return memoizedChatProps;
}

export const { Provider: ComponentProvider, useContainer: useChatBotAIProps } =
  createContainer(useContainer);

export const ChatBotAIPropsProvider = ({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState;
}) => {
  return (
    <StreamingTextProvider>
      <ComponentProvider initialState={initialState}>
        {children}
      </ComponentProvider>
    </StreamingTextProvider>
  );
};
