import { apiClient, localApiClient } from "queries/constants";
import { createSectionState } from "ui/lib/helpers";
import { Workout } from "./types";

export type GetWorkoutOptions = {
  local?: boolean;
  createSectionState?: boolean;
};

const getWorkoutByID =
  (workoutID: string, options?: GetWorkoutOptions) => async () => {
    if (!workoutID) return null;
    const client = options?.local ? localApiClient : apiClient;
    const path = options?.local ? "/api/workouts/" : "/workouts/";
    const { data } = await client.get(path + workoutID);
    const sectionState = options?.createSectionState
      ? createSectionState(data)
      : null;

    return { ...data, sectionState };
  };

const deleteWorkoutByID = async (workoutID: string) => {
  await apiClient.delete(`/workouts/${workoutID}`);
};

const updateWorkoutByID = async (workoutID: string, data: Workout) => {
  const response = await apiClient.patch<Workout>(
    "/workouts/" + workoutID,
    data
  );

  return response.data;
};

export { deleteWorkoutByID, getWorkoutByID, updateWorkoutByID };
