"use client";
import { motion } from "framer-motion";
import remarkDFM from "remark-gfm";
import { cn } from "ui/lib/utils";

import { Message } from "ai/react";
import { RefreshCw as RefreshCwIcon } from "lucide-react";
import React, { memo } from "react";
import ReactMarkdown from "react-markdown";
import { TypingIndicator } from "../typing-indicator";

type AssistantMessage = Message & {
  display?: React.ReactNode;
};

export const AssistantMessageBubbleContent = memo(
  ({
    content,
    isLastMessage,
    loading,
  }: {
    content: string;
    isLastMessage?: boolean;
    loading?: boolean;
  }) => {
    return (
      <ReactMarkdown
        rehypePlugins={[remarkDFM]}
        components={{
          em({ node, children }) {
            if (children.includes("AI_LOADING") && isLastMessage) {
              return <TypingIndicator />;
            }
            if (children.includes("AI_LOADING")) return null;

            return (
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(128.99deg, #6AD3F4 -52.29%, #6AF478 18.9%, #D2FF51 108.52%)",
                  backgroundClip: "text",
                }}
                className="text-transparent bg-clip-text"
              >
                {children}
              </span>
            );
          },
          strong({ node, children }) {
            return (
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(128.99deg, #6AD3F4 -52.29%, #6AF478 18.9%, #D2FF51 108.52%)",
                  backgroundClip: "text",
                }}
                className="text-transparent bg-clip-text font-bold"
              >
                {children}
              </span>
            );
          },
          li({ children, node, ...props }) {
            return (
              <li className="">
                <div>{children}</div>
              </li>
            );
          },
        }}
        linkTarget="_blank"
      >
        {`${content} ${loading ? "*AI_LOADING*" : ""}`}
      </ReactMarkdown>
    );
  }
);

export const AssistantMessageBubble = memo(
  ({
    message,
    index,
    messagesLength,
    showAssistantAvatar,
    handleRefresh,
    loading,
    isLastMessage,
  }: {
    message: AssistantMessage;
    index: number;
    messagesLength: number;
    showAssistantAvatar?: boolean;
    handleRefresh?: (index: number) => void;
    loading?: boolean;
    isLastMessage?: boolean;
  }) => {
    return (
      <div>
        <div className="w-full flex space-x-2 group">
          <div className="flex space-x-2 items-center justify-start">
            <motion.div
              initial={messagesLength === 1 && { opacity: 0, y: 50 }}
              animate={messagesLength === 1 && { opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              style={{
                maxWidth: `calc(100vw - 50px)`,
              }}
              className={cn(
                "chatbot-markdown min-w-sm w-auto space-y-0 mr-auto text-foreground rounded-2xl rounded-tl-sm chat"
              )}
            >
              {showAssistantAvatar ? (
                <div className="flex">
                  <div className="p-[.40rem] rounded-full bg-secondary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="none"
                      className="w-5 h-5"
                    >
                      <path
                        fill="url(#a)"
                        d="m15.343 11.085-3.661-1.562a3.8 3.8 0 0 1-1.978-1.934l-1.597-3.58a.358.358 0 0 0-.651 0L5.858 7.59a3.8 3.8 0 0 1-1.977 1.934L.213 11.087a.344.344 0 0 0 0 .636l3.73 1.613a3.8 3.8 0 0 1 1.968 1.942l1.547 3.512c.123.279.528.281.65 0l1.596-3.575a3.8 3.8 0 0 1 1.978-1.934l3.66-1.562a.344.344 0 0 0 0-.636z"
                      />
                      <path
                        fill="url(#b)"
                        d="m20.03 4.14-2.08-.888a2.15 2.15 0 0 1-1.123-1.098L15.918.119a.203.203 0 0 0-.368 0l-.909 2.035a2.15 2.15 0 0 1-1.122 1.098l-2.083.888a.195.195 0 0 0 0 .36l2.12.918c.5.216.9.612 1.117 1.105l.879 1.995c.07.158.3.158.37 0l.907-2.031a2.15 2.15 0 0 1 1.122-1.098l2.081-.888a.195.195 0 0 0 0-.361z"
                      />
                      <defs>
                        <linearGradient
                          id="a"
                          x1={-8.712}
                          x2={18.13}
                          y1={-3.452}
                          y2={18.787}
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#6AD3F4" />
                          <stop offset={0.443} stopColor="#6AF478" />
                          <stop offset={1} stopColor="#D2FF51" />
                        </linearGradient>
                        <linearGradient
                          id="b"
                          x1={6.365}
                          x2={21.616}
                          y1={-4.12}
                          y2={8.515}
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#6AD3F4" />
                          <stop offset={0.443} stopColor="#6AF478" />
                          <stop offset={1} stopColor="#D2FF51" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              ) : null}
              <div className="prose prose-base prose-p:margin-y-1 break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0">
                {message.content ? (
                  <AssistantMessageBubbleContent
                    content={message.content}
                    isLastMessage={isLastMessage}
                    loading={loading}
                  />
                ) : null}
                {message.display ? <>{message.display}</> : null}
              </div>
            </motion.div>
            {index === messagesLength - 1 &&
            typeof handleRefresh === "function" ? (
              <button
                onClick={() => handleRefresh(index)}
                className="opacity-0 group-hover:opacity-100 p-2 rounded-full bg-secondary shadow-button"
              >
                <RefreshCwIcon className="w-3 h-3 text-foreground" />
              </button>
            ) : (
              <div className="min-w-[20px]" />
            )}
          </div>
        </div>
      </div>
    );
  }
);
