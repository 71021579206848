import { memo } from "react";
import styles from "./styles.module.css";

export const TypingIndicator = memo(() => {
  return (
    <svg
      viewBox="8 4 8 16"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.cursor}
    >
      <rect x="10" y="6" width="4" height="12" fill="transparent" />
    </svg>
  );
});
